import { QueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { TOAST_CONFIG } from '../hooks/use-toast.hook';
import { addError } from './datadog';
import { AxiosError } from 'axios';
import { safeRefreshTokenUser } from './services/auth.service';
import { getAxiosErrorMessage } from './services';

export enum QueryKey {
  ThreadsDataset = 'threads-dataset',
  ThreadsList = 'threads-list',
  ShortcutsList = 'shortcuts-list',
  DatasourceList = 'datasource-list',
  PrivateModelsList = 'private-models-list',
  AgentsList = 'agents-list',
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 4000, // how long is data considered fresh for?
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 1,
      onError: (error: AxiosError) => {
        addError(error);

        const { status } = error || {};

        if (status === 401) {
          safeRefreshTokenUser();
          return;
        }

        const { message } = getAxiosErrorMessage(error);
        enqueueSnackbar(message, { ...TOAST_CONFIG, variant: 'error' });
      },
    },
  },
});

export default queryClient;
