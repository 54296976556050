import { FC } from 'react';
import classnames from 'classnames';
import FlatButton from '@/components/flat-button';
import useUserSettings from '@/hooks/use-user-settings.hook';
import { createUseStyles } from 'react-jss';
import Tooltip from '@/components/tooltip';
import { addAction } from '@/lib/datadog';
import useThread from '@/hooks/use-thread.hook';

const useStyles = createUseStyles({
  searchButton: {
    '&.enabled': {
      color: '#04368d',
    },
  },
});

const PublicInternetSearchButton: FC = () => {
  const styles = useStyles();
  const { enablePublicSearch, disablePublicSearch, publicSearchAvailable, publicSearchEnabled } =
    useUserSettings();
  const { thread } = useThread();

  const handleToggleSearch = () => {
    addAction('public_search_toggle', { enabled: !publicSearchEnabled, threadId: thread.id });

    if (publicSearchEnabled) {
      disablePublicSearch();
      return;
    }

    enablePublicSearch();
  };

  if (!publicSearchAvailable) {
    return null;
  }

  const compClass = classnames(styles.searchButton, { enabled: publicSearchEnabled });

  return (
    <Tooltip title="Include public internet search as a data source" enterDelay={500}>
      <FlatButton
        icon="globe"
        label="Search"
        size="small"
        onClick={handleToggleSearch}
        className={compClass}
        shimmer={publicSearchEnabled}
        shimmerOnce
      />
    </Tooltip>
  );
};

export default PublicInternetSearchButton;
