import { Box, Stack, Theme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Loader from '../loader';
import { createUseStyles } from 'react-jss';
import Text from '../text';
import Switch from '../form/switch';
import FlatButton from '../flat-button';
import useUserSettings from '@/hooks/use-user-settings.hook';
import { Datasource } from '@/contexts/user-settings/helpers';
import ScrollBox from '../scroll-box';
import TextInput from '../form/text-input';

const useStyles = createUseStyles((theme: Theme) => ({
  list: {
    overflowX: 'hidden',
    paddingLeft: 18,
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginRight: 18,
    paddingBottom: 8,
  },
  item: {
    width: '100%',
    gap: 8,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    '& .MuiFormControl-root': {
      margin: 0,
    },
    paddingTop: 8,
    paddingBottom: 8,
  },
  activeIcon: {
    opacity: 0,
  },
  searchInput: {
    width: 150,
  },
}));

interface Props {
  datasources: Datasource[];
  isLoading: boolean;
  emptyMessage?: string;
  keyword: string;
  onSearch: (value: string) => void;
}

const DatasourceList: FC<Props> = ({ datasources, isLoading, emptyMessage, onSearch, keyword }) => {
  const styles = useStyles();
  const [selectAll, setSelectAll] = useState(false);

  const { enableDatasource, disableDatasource } = useUserSettings();

  const handleToggleDatasource = (datasourceId: string, checked: boolean) => {
    if (checked) {
      enableDatasource(datasourceId);
      return;
    }

    disableDatasource(datasourceId);
  };

  const handleSelectAll = (checked: boolean) => {
    const datasourceIds = datasources.map(({ model }) => model.id);

    if (checked) {
      enableDatasource(datasourceIds);
      setSelectAll(true);
      return;
    }

    setSelectAll(false);
    disableDatasource(datasourceIds);
  };

  useEffect(() => {
    if (datasources.every(({ enabled }) => enabled)) {
      setSelectAll(true);
      return;
    }

    setSelectAll(false);
  }, [datasources]);

  const hasDatasources = !!datasources.length;
  const showEmptyMessage = !isLoading && !hasDatasources && !!emptyMessage;
  const canSelectAll = hasDatasources;

  return (
    <Box className={styles.list}>
      <Stack direction="row" justifyContent="space-between" className={styles.toolbar}>
        <Box className={styles.searchInput}>
          <TextInput
            name="datasource-search"
            value={keyword}
            onChange={onSearch}
            size="tiny"
            startIcon="search"
            placeholder="Search"
            clearable
            fullWidth={false}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          <FlatButton
            size="small"
            label={selectAll ? 'Disable All' : 'Enable All'}
            onClick={() => handleSelectAll(!selectAll)}
            disabled={!canSelectAll}
          />
        </Stack>
      </Stack>

      {isLoading && <Loader />}

      {!isLoading && (
        <ScrollBox max={500} emptyMessage={showEmptyMessage ? emptyMessage : ''}>
          {datasources.map(({ model, enabled }) => {
            const { id, name, description } = model;
            const hasDescription = !!description;

            return (
              <Stack key={id} className={styles.item}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Box flexGrow={1}>
                    <Text size="small" bold>
                      {name}
                    </Text>
                  </Box>
                  <Stack justifyContent="center">
                    <Switch
                      name="datasource"
                      checked={enabled}
                      onChange={(checked: boolean) => handleToggleDatasource(id, checked)}
                      size="small"
                    />
                  </Stack>
                </Stack>

                {hasDescription && <Text size="small">{description}</Text>}
              </Stack>
            );
          })}
        </ScrollBox>
      )}
    </Box>
  );
};

export default DatasourceList;
