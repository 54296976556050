import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  disabled?: boolean;
  once?: boolean;
}

const useStyles = createUseStyles<string, { once: boolean }>({
  shimmer: {
    '-webkit-mask': 'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
    backgroundRepeat: 'no-repeat',
    animation: ({ once }) => `$shimmer 1.5s ${!once ? 'infinite' : '1'}`,
  },

  '@keyframes shimmer': {
    '100%': {
      WebkitMaskPosition: 'left',
    },
  },
});

const Shimmer: FC<Props> = ({ children, disabled = false, once = false }) => {
  const styles = useStyles({ once });

  return <Box className={disabled ? '' : styles.shimmer}>{children}</Box>;
};

export default Shimmer;
