import { Stack } from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Text from '../text';
import { useQuery } from 'react-query';
import TextInput from '../form/text-input';
import useThread from '@/hooks/use-thread.hook';
import { getAllThreads } from '@/lib/services/thread.service';
import { cloneDeep, debounce } from 'lodash';
import { getNiceRecentDate } from '@/lib/date-helpers';
import SidebarList from './sidebar-list';
import { QueryKey } from '@/lib/query-client';
import useThreadToast from '@/hooks/use-thread-toast.hook';
import TabHeader from './tab-header';
import TabContent from './tab-content';
import { ThreadModel } from '@/lib/models/thread/thread.model';

const HistoryTab: FC = () => {
  const [keyword, setKeyword] = useState('');
  const [keywordVal, setKeywordVal] = useState('');
  const [threads, setThreads] = useState<ThreadModel[]>([]);

  const { loadThread, thread } = useThread();
  const { clear } = useThreadToast();

  const { data = [], isLoading } = useQuery([QueryKey.ThreadsList, keyword], async () =>
    getAllThreads(keyword)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleChangeKeyword = useCallback(
    debounce((keyword: string) => {
      setKeyword(keyword);
    }, 600),
    []
  );

  const handleKeywordValChange = useCallback(
    (value: string) => {
      setKeywordVal(value);
      debounceHandleChangeKeyword(value);
    },
    [debounceHandleChangeKeyword]
  );

  const handleLoadThread = useCallback(
    (threadId: string) => {
      loadThread(threadId).then((loaded) => {
        if (loaded) {
          clear();
        }
      });
    },
    [loadThread, clear]
  );

  const listItems = useMemo(() => {
    return (threads || []).map(({ id: threadId, latestUserMessage }) => {
      const { timestamp, content, shortcut, context } = latestUserMessage || {};

      const hasShortcut = !!shortcut;
      const isFile = context?.type === 'file';

      let blurb: ReactNode = content;
      if (hasShortcut) {
        blurb = `Shortcut: ${shortcut.name}`;
      } else if (isFile) {
        blurb = <>File Upload: {context?.name || <Text italic>not found</Text>}</>;
      }

      return {
        title: getNiceRecentDate(timestamp!),
        content: (
          <Text size="small" dotdot>
            {blurb}
          </Text>
        ),
        key: `thread-${threadId}`,
        onClick: () => handleLoadThread(threadId),
        active: thread.id === threadId,
      };
    });
  }, [threads, thread.id, handleLoadThread]);

  useEffect(() => {
    if (!thread.latestUserMessage) {
      return;
    }

    setThreads((old) => {
      const updated = cloneDeep(old);

      const activeIndex = updated.findIndex(({ id }) => id === thread.id);

      if (activeIndex == -1) {
        updated.unshift(thread);
      } else {
        updated[activeIndex] = thread;
      }

      return updated;
    });
  }, [thread]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }

    setThreads(data);
  }, [data, isLoading]);

  return (
    <Stack gap={2} width="100%" height="100%" maxHeight="100%">
      <TabHeader icon="history" title="History" />
      <TabContent>
        <TextInput
          name="history-search"
          value={keywordVal}
          onChange={handleKeywordValChange}
          size="small"
          startIcon="search"
          placeholder="Search history"
          clearable
        />
      </TabContent>
      <SidebarList isLoading={isLoading} items={listItems} />
    </Stack>
  );
};

export default HistoryTab;
