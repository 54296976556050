import { AxiosError, CanceledError, AxiosResponse } from 'axios';
import { JsonObject } from '../helpers';

export const SAFE_LIMIT = 1000;

export type ApiResponse = JsonObject | JsonObject[] | null;

export type ApiFileResponse = {
  name: string;
  summary: string;
  purpose: string;
};

export type ApiUploadResponse = {
  file?: ApiFileResponse;
  threadId: string;
  error?: string;
};

export type ServiceResponse = {
  error?: string;
};

export const DEFAULT_SERVICE_ERROR = 'There was a service error';

export const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};

// https://github.com/axios/axios?tab=readme-ov-file#error-types
export const getAxiosErrorMessage = (
  err: unknown,
  errorCodes?: Record<number, string>
): { message: string; clientside: boolean } => {
  const { code = '', status = 0 } = (err || {}) as AxiosError;

  if (err instanceof CanceledError || code === 'ERR_CANCELED') {
    return { message: 'You cancelled the request', clientside: true };
  }

  let message = 'There was a service error';

  if (['ECONNABORTED', 'ETIMEDOUT', 'ERR_NETWORK', 'ERR_FR_TOO_MANY_REDIRECTS'].includes(code)) {
    message = 'There was a network error';
  }

  // user-defined response code
  if (errorCodes?.[status]) {
    message = errorCodes[status];
  }

  const clientside = [
    'ERR_BAD_OPTION_VALUE',
    'ERR_BAD_OPTION',
    'ERR_DEPRECATED',
    'ERR_CANCELED',
    'ERR_NOT_SUPPORT',
    'ERR_INVALID_URL',
  ].includes(code);

  return { message, clientside };
};
