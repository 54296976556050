import { Box, Stack, Theme } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import Text from '../text';
import { createUseStyles } from 'react-jss';
import Icon from '../icon';
import useUserSettings from '@/hooks/use-user-settings.hook';
import DatasourceList from './datasource-list';
import TabHeader from './tab-header';
import TabContent from './tab-content';

const useStyles = createUseStyles((theme: Theme) => ({
  noDatasourcesCard: {
    marginTop: 16,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'center',
  },
  publicSearchCard: {
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
}));

const UserSettingsTab: FC = () => {
  const styles = useStyles();
  const [keyword, setKeyword] = useState('');

  const { datasources, isLoading } = useUserSettings();

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
  };

  const filteredDatasources = useMemo(() => {
    const safeKeyword = String(keyword).trim().toLowerCase();

    return Array.from(datasources.entries())
      .filter(([, { model }]) => {
        const { name, description, isPublicInternet } = model;

        // there is a separate, thread-level control for working with public internet search
        if (isPublicInternet) {
          return false;
        }

        const keywordMatch =
          String(name).toLowerCase().includes(safeKeyword) ||
          String(description).toLowerCase().includes(safeKeyword);

        return keywordMatch;
      })
      .map(([, datasource]) => datasource);
  }, [datasources, keyword]);

  const hasDatasources = !!filteredDatasources.length;
  const noResults = !isLoading && !!keyword.length && !hasDatasources;
  const noAvailableDatasources = !keyword.length && !hasDatasources && !isLoading;

  return (
    <Stack gap={2} width="100%" height="100%" maxHeight="100%">
      <TabHeader icon="settings" title="Global Settings" />

      <TabContent>
        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <Icon name="manage-search" />
            <Text>Default Data Sources</Text>
          </Stack>

          {noAvailableDatasources && (
            <Box className={styles.noDatasourcesCard}>
              <Text italic>No data sources available</Text>
            </Box>
          )}
        </Stack>
      </TabContent>

      {!noAvailableDatasources && (
        <DatasourceList
          isLoading={isLoading}
          datasources={filteredDatasources}
          emptyMessage={noResults ? 'No matching data sources found' : ''}
          keyword={keyword}
          onSearch={handleKeywordChange}
        />
      )}
    </Stack>
  );
};

export default UserSettingsTab;
