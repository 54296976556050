import { ReactNode, FC, useState, useEffect, useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { LoadState, isUUID } from '@/lib/helpers';
import { ShortcutMessage, ShortcutModel } from '@/lib/models/shortcut.model';
import useApp from '@/hooks/use-app.hook';
import { getThreadById, makeDeckMessage } from '@/lib/services/thread.service';
import { MessageStatus, ThreadContext, ThreadError, ThreadSettings } from './helpers';
import useThreadToast from '@/hooks/use-thread-toast.hook';
import {
  StreamControlSignal,
  StreamMessage,
  stopStreamClient,
  streamUserMessage,
} from '@/lib/services/thread-stream.service';
import useUserSettings from '@/hooks/use-user-settings.hook';
import { ThreadModel } from '@/lib/models/thread/thread.model';
import { ThreadMessageModel } from '@/lib/models/thread/thread-message.model';
import { ThreadContext as ThreadContextMap } from '@/lib/models/thread';
import { getTimestamp } from '@/lib/date-helpers';

interface Props {
  children: ReactNode;
}

const getUrlThreadId = (): string => {
  const threadId = String(window.location.pathname).split('/').pop() || '';
  return isUUID(threadId) ? threadId : '';
};

export const ThreadProvider: FC<Props> = ({ children }) => {
  const [thread, setThread] = useState<ThreadModel>(new ThreadModel());
  const [settings, setSettings] = useState<ThreadSettings>({ privateModelId: '', agentId: '' });
  const [loadState, setLoadState] = useState<LoadState>('unloaded');
  const [threadLoadState, setThreadLoadState] = useState<LoadState>('unloaded');
  const [messageStatus, setMessageStatus] = useState<MessageStatus>('open');
  const [controlSignal, setControlSignal] = useState<StreamControlSignal | null>(null);
  const [working, setWorking] = useState(false);
  const [streamContent, setStreamContent] = useState('');

  const { signedIn } = useApp();
  const { toast } = useThreadToast();
  const { datasources, disablePublicSearch } = useUserSettings();

  const hasOnDeckShortcut = useMemo(() => {
    return thread.conversation.some(({ onDeck, shortcut }) => onDeck && !!shortcut);
  }, [thread]);

  const syncSettings = useCallback(
    (thread: ThreadModel, agentId?: string) => {
      setSettings({
        privateModelId: thread.latestPrivateModelId,
        agentId: agentId || thread.latestAgentId,
      });

      // results from public internet search context are less than great right now, so ensure it is off by default
      console.log('disabling internet search');
      disablePublicSearch();
    },
    [disablePublicSearch]
  );

  const getDefaultDatasourceIds = useCallback(() => {
    return Array.from(datasources.values())
      .filter(({ enabled }) => enabled)
      .map(({ model }) => model.id);
  }, [datasources]);

  const loadThread = useCallback(
    async (threadId?: string, agentId?: string): Promise<boolean> => {
      if (threadLoadState === 'loading') {
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.SendingSwitchThread);
        return false;
      }

      if (hasOnDeckShortcut) {
        toast(ThreadError.WorkingSwitchThread);
        return false;
      }

      let thread: ThreadModel | null = null;

      setThreadLoadState('loading');

      if (threadId) {
        thread = await getThreadById(threadId);
      } else {
        thread = new ThreadModel({
          agentId,
        });
      }

      if (!thread) {
        toast(ThreadError.NoDisassemble, 'error');
        return false;
      }

      setThread(thread);

      syncSettings(thread, agentId);

      setThreadLoadState('loaded');

      return true;
    },
    [threadLoadState, messageStatus, hasOnDeckShortcut, toast, syncSettings]
  );

  const addMessage = useCallback((message: ThreadMessageModel) => {
    setThread((old) => {
      const updated = cloneDeep(old);
      updated.conversation.push(message);
      return updated;
    });
  }, []);

  const streamMessage = useCallback(
    (message: ThreadMessageModel) => {
      const onMessage = ({
        error,
        errorCode,
        content,
        isComplete,
        threadId: streamThreadId,
        threadMessage: streamThreadMessage,
      }: StreamMessage) => {
        if (streamThreadId !== thread.id) {
          // @todo abort the stream, yikes
          setMessageStatus('open');
          return;
        }

        if (error) {
          // no need to abort the stream, thread service already took care of that
          setMessageStatus('open');

          if (errorCode === 401) {
            toast(ThreadError.SessionExpired, 'warning');
            return;
          }

          toast(ThreadError.NoDisassemble, 'error');
          return;
        }

        setMessageStatus('stream');
        setStreamContent(content);

        if (isComplete) {
          setMessageStatus('open');

          if (!streamThreadMessage) {
            toast(ThreadError.NoDisassemble, 'error');
            return;
          }

          setThread((old) => {
            const updated = cloneDeep(old);
            updated.conversation.push(streamThreadMessage);

            // mark the user message as no longer being on-deck
            const userMessageIndex = updated.conversation.findIndex(({ id }) => id === message.id);
            if (userMessageIndex > -1) {
              updated.conversation[userMessageIndex].onDeck = false;
            }

            return updated;
          });

          setStreamContent('');
        }
      };

      const onContext = (threadContext: ThreadContextMap) => {
        setThread((old) => {
          const updated = cloneDeep(old);
          updated.context = threadContext;
          return updated;
        });
      };

      const onControl = (controlSignal: StreamControlSignal | null) => {
        setControlSignal(controlSignal || null);
      };

      message.agentId = settings.agentId;
      message.privateModelId = settings.privateModelId;
      // @todo in the future, datasourceIds will be a comp of thread and user settings
      message.datasourceIds = getDefaultDatasourceIds();

      streamUserMessage(message, onMessage, onContext, onControl);
    },
    [thread.id, settings, toast, getDefaultDatasourceIds]
  );

  const closeStream = useCallback(() => {
    // close the sse client
    stopStreamClient();
    // @todo if we set the status back to open, the streamed content will disappear, but we might not have enough to finish the message
    setMessageStatus('open');
  }, []);

  const loadShortcut = useCallback(
    (shortcut: ShortcutModel) => {
      if (!thread.id) {
        toast(ThreadError.NotFoundThread, 'error');
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.SendingLoadShortcut);
        return false;
      }

      if (thread.locked) {
        return false;
      }

      // If there is already a shortcut in the thread, but the user has not yet filled out anything, simply replace it with this one being loaded
      const existIndex = thread.conversation.findIndex(
        ({ onDeck, shortcut }) => onDeck && shortcut
      );
      let removeIndex = -1;

      if (existIndex > -1) {
        // user is already working on an on-deck shortcut, don't replace it
        if (working) {
          toast(ThreadError.WorkingLoadShortcut);
          return false;
        }
        removeIndex = existIndex;
      }

      const deckMessage = makeDeckMessage(thread.id, 'user', '', shortcut.message);

      setThread((old) => {
        const updated = cloneDeep(old);
        if (removeIndex > -1) {
          updated.conversation.splice(removeIndex, 1);
        }

        updated.conversation.push(deckMessage);

        return updated;
      });

      return true;
    },
    [thread, working, messageStatus, toast]
  );

  const removeDeckMessage = useCallback((messageId: string) => {
    setThread((old) => {
      const updated = cloneDeep(old);
      const messageIndex = updated.conversation.findIndex(({ id }) => id === messageId);

      if (messageIndex > -1) {
        updated.conversation.splice(messageIndex, 1);
      }

      return updated;
    });

    return true;
  }, []);

  const handleSetPrivateModelId = useCallback((privateModelId: string) => {
    setSettings((old) => ({
      ...old,
      privateModelId,
    }));
  }, []);

  const handleSetAgentId = useCallback((agentId: string) => {
    setSettings((old) => ({
      ...old,
      agentId,
    }));
  }, []);

  const sendDeckMessage = useCallback(
    (message: ThreadMessageModel) => {
      if (!thread.id) {
        toast(ThreadError.NotFoundThread, 'error');
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.Sending);
        return false;
      }

      const updatedThread = cloneDeep(thread);
      const messageIndex = updatedThread.conversation.findIndex(({ id }) => id === message.id);
      if (messageIndex === -1) {
        toast(ThreadError.NotFoundMessage, 'error');
        return false;
      }

      if (thread.locked) {
        return false;
      }

      setMessageStatus('send');

      const { shortcut } = message;

      // mark the on-deck message as having been sent
      setThread((old) => {
        const updated = cloneDeep(old);
        updated.conversation[messageIndex].onDeck = false;
        updated.conversation[messageIndex].shortcut = shortcut;
        updated.conversation[messageIndex].timestamp = getTimestamp(new Date(), true) || 0;
        return updated;
      });

      streamMessage(message);

      return true;
    },
    [thread, messageStatus, toast, streamMessage]
  );

  /*
   * Given text content that the user enters via MessageControl, create a new thread message, send
   * the message off for inference, then create a new thread message with the response.
   */
  const sendMessage = useCallback(
    (content: string, shortcut?: ShortcutMessage) => {
      if (!thread.id) {
        toast(ThreadError.NotFoundThread, 'error');
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.Sending);
        return false;
      }

      if (hasOnDeckShortcut) {
        toast(ThreadError.Working);
        return false;
      }

      if (thread.locked) {
        return false;
      }

      setMessageStatus('send');

      const deckMessage = makeDeckMessage(thread.id, 'user', content, shortcut);

      // because we are sending the message right away, lock the shortcut form by marking it as no longer on-deck
      if (shortcut) {
        deckMessage.onDeck = false;
      }

      addMessage(deckMessage);

      // using sendDeckMessage here would create consistency, but how to work with the recently updated thread?

      streamMessage(deckMessage);

      return true;
    },
    [messageStatus, hasOnDeckShortcut, thread, toast, addMessage, streamMessage]
  );

  // run once on boot
  useEffect(() => {
    if (!signedIn) {
      return;
    }
    if (loadState !== 'unloaded') {
      return;
    }
    setLoadState('loading');
  }, [signedIn, loadState]);

  // load the users thread, their active thread, and the messages for the active thread
  useEffect(() => {
    if (loadState !== 'loading') {
      return;
    }

    const threadId = getUrlThreadId();

    if (!threadId) {
      setLoadState('loaded');
      return;
    }

    getThreadById(threadId).then((activeThread) => {
      if (activeThread) {
        setThread(activeThread);
        syncSettings(activeThread);
        setThreadLoadState('loaded');
      } else {
        // @todo error toast this, thread was not found
        history.pushState({}, '', '/home');
      }

      setLoadState('loaded');
    });
  }, [loadState, syncSettings]);

  // ensure the loaded thread is synced with the /home/<thread id> URL
  useEffect(() => {
    if (loadState !== 'loaded') {
      return;
    }

    if (!String(window.location.pathname).startsWith('/home')) {
      return;
    }

    const { id } = thread;

    const threadId = getUrlThreadId();

    if (threadId !== id) {
      history.pushState({}, '', `/home/${id}`);
    }
  }, [thread, loadState]);

  // ensure that the working flag is turned off if there is no on-deck shortcut
  useEffect(() => {
    const onDeckShortcut = thread.conversation.some(({ onDeck, shortcut }) => onDeck && !!shortcut);
    if (!onDeckShortcut) {
      setWorking(false);
    }
  }, [thread.conversation]);

  return (
    <ThreadContext.Provider
      value={{
        loadState: threadLoadState,
        thread,
        settings,
        sendMessage,
        controlSignal,
        messageStatus,
        working,
        streamContent,
        setWorking,
        loadShortcut,
        loadThread,
        addMessage,
        sendDeckMessage,
        removeDeckMessage,
        closeStream,
        setPrivateModelId: handleSetPrivateModelId,
        setAgentId: handleSetAgentId,
      }}
    >
      {children}
    </ThreadContext.Provider>
  );
};
