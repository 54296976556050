import { getDateObj } from '@/lib/date-helpers';
import {
  Citation,
  ThreadContextSource,
  ThreadRole,
  UserEventContext,
  UserEventRole,
  Violations,
} from '.';
import { getUUID, JsonObject } from '../../helpers';
import { ShortcutMessage } from '../shortcut.model';
import { ReactNode } from 'react';

export class ThreadMessageModel {
  id: string;
  threadId: string;
  content: string;
  datasourceIds: string[];
  privateModelId?: string;
  agentId?: string;
  eventRole: UserEventRole;
  timestamp: number;
  violations?: Violations;
  eventContext?: UserEventContext;
  citations?: Citation[];
  shortcut?: ShortcutMessage;
  onDeck: boolean;
  component?: ReactNode;

  constructor(props: JsonObject = {}) {
    const data = props as Record<
      keyof ThreadMessageModel | 'assistantId' | 'contextDetails',
      unknown
    >;

    this.threadId = (data.threadId as string) || '';
    this.content = (data.content as string) || '';
    this.datasourceIds = (data.datasourceIds as string[]) || [];
    this.privateModelId = (data.privateModelId as string) || '';
    this.agentId = (data.assistantId as string) || (data.agentId as string) || '';
    this.timestamp = (data.timestamp as number) || 0;
    this.violations = (data.violations as Violations) || {
      intent: false,
      sensitiveData: false,
      access: false,
      input: false,
      output: false,
    };

    this.eventContext =
      (data.contextDetails as UserEventContext) ||
      (data.eventContext as UserEventContext) ||
      undefined;

    if (data.citations) {
      this.citations = data.citations as Citation[];
    }

    if (data.shortcut) {
      this.shortcut = data.shortcut as ShortcutMessage;
    }

    this.eventRole = (data.role as UserEventRole) || 'system';

    // this value is only used by react to uniquely identify component that render the message
    this.id = `${this.threadId}|${this.role}|${getUUID()}`;

    this.onDeck = !!data.onDeck;

    // @todo is this just a safeguard against showing the user a system message?
    if (this.context) {
      this.content = '';
    }
  }

  get role(): ThreadRole {
    switch (String(this.eventRole).toLowerCase()) {
      case 'user':
      case 'context': // @todo is this so file uploads appear as user uploads? need some nuance here
        return 'user';
      default:
        return 'system';
    }
  }

  get createdAt(): Date | null {
    return this.timestamp ? getDateObj(this.timestamp) : null;
  }

  get context(): ThreadContextSource | null {
    if (this.eventRole !== 'context' || !this.eventContext) {
      return null;
    }

    const { origin, type, name, source, summary = '' } = this.eventContext || {};

    if (origin === 'user' && type === 'file') {
      return {
        type: 'file',
        name: name || 'Unknown file',
        ext: String(name).split('.').pop() || '',
        summary,
      };
    }

    if (origin === 'enterprise' && type === 'document') {
      return {
        type: 'document',
        name: name || '',
        url: source || '',
      };
    }

    if (origin === 'web' && type === 'document') {
      return {
        type: 'web',
        name: name || '',
        url: source || '',
      };
    }

    return {
      type: 'unknown',
      name: name || 'Unknown Context',
    };
  }

  get locked(): boolean {
    return !!this.violations?.intent;
  }
}
