import { FC } from 'react';
import Icon, { IconType } from '../icon';
import { Stack, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Text from '../text';
import Shimmer from '../shimmer';

interface Props {
  label: string;
  icon?: IconType;
  color?: 'black' | 'grey' | 'error';
  onClick?: () => void;
  size?: 'medium' | 'small';
  className?: string;
  disabled?: boolean;
  shimmer?: boolean;
  shimmerOnce?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  flatButton: {
    cursor: 'pointer',
    gap: 4,
    '& .MuiTypography-root': {
      lineHeight: '100%',
    },
    '&:not(.small)': {
      '& .MuiTypography-root': {
        marginTop: -2,
      },
    },
    '&.grey': {
      color: theme.palette.grey[400],
    },
    '&.error': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      opacity: 0.8,
    },
    '&.disabled': {
      cursor: 'default',
      color: theme.palette.grey[400],
      pointerEvents: 'none',
      '&:hover': {
        color: theme.palette.grey[400],
      },
    },
  },
  label: {},
}));

const FlatButton: FC<Props> = ({
  label,
  icon,
  onClick,
  color,
  size = 'medium',
  className = '',
  disabled = false,
  shimmer = false,
  shimmerOnce = false,
}) => {
  const styles = useStyles();
  const IconEl = icon ? <Icon name={icon} size={size} /> : null;
  const compClass = classNames(styles.flatButton, color, className, size, { disabled });

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  const ButtonEl = (
    <Stack
      className={compClass}
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      onClick={handleOnClick}
    >
      {IconEl}
      <Text className={styles.label} size={size}>
        {label}
      </Text>
    </Stack>
  );

  if (shimmer) {
    return <Shimmer once={shimmerOnce}>{ButtonEl}</Shimmer>;
  }

  return ButtonEl;
};

export default FlatButton;
