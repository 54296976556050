import { AxiosError, AxiosProgressEvent, CanceledError } from 'axios';
import { FileWithPath } from 'react-dropzone';
import { ApiFileResponse, ApiUploadResponse, getAxiosErrorMessage } from './';
import { JsonObject } from '../helpers';
import { get, post } from './sp-api.service';

export type AllowedFiletype = { extension: string; mimetype: string };

export type FileSettings = {
  maxFileSizeBytes: number;
  maxFilesPerUpload: number;
  allowedFileTypes: AllowedFiletype[];
};

type UploadErrorResponse = {
  details: string;
  errors: { field: string; rule: string }[];
};

export const uploadContextFile = async (
  file: FileWithPath,
  threadId: string,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
  controller?: AbortController
): Promise<ApiUploadResponse> => {
  return postUploadFile(`/files/context/${threadId}`, file, onUploadProgress, controller).catch(
    (err) => {
      // user cancelled the upload
      if (err instanceof CanceledError) {
        return { threadId, error: '' };
      }

      const { data } = (err as AxiosError)?.response || {};

      const errorMessage = (data as UploadErrorResponse)?.errors?.[0]?.rule;

      const errorCodes = {
        403: 'The file was blocked due to security concerns',
      };

      return {
        file: {
          name: file.name,
          summary: '',
          purpose: '',
        },
        threadId,
        error: errorMessage || getAxiosErrorMessage(err, errorCodes).message,
      };
    }
  );
};

export const getSettings = async (): Promise<FileSettings> => {
  const response = await get('/files/settings');

  if (!response) {
    return {
      maxFileSizeBytes: 0,
      maxFilesPerUpload: 0,
      allowedFileTypes: [],
    };
  }

  return response as FileSettings;
};

const postUploadFile = async (
  path: string,
  file: FileWithPath,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
  controller?: AbortController
): Promise<ApiUploadResponse> => {
  const formData = new FormData();
  formData.append('files', file, file.name);

  return post(path, formData, {
    withCredentials: false,
    timeout: 1000 * 60 * 8, // 8 minutes
    signal: controller?.signal || new AbortController().signal,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  }).then((data: JsonObject) => {
    const { files, threadId, error } = data as {
      files: ApiFileResponse[];
      threadId: string;
      error?: string;
    };

    const file: ApiFileResponse | undefined = files?.[0] || undefined;

    return {
      file,
      threadId,
      error,
    };
  });
};
