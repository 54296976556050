import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { get as getLocal, set as setLocal } from '@/lib/local-storage';
import { Datasource, UserSettingsContext } from './helpers';
import { useQuery } from 'react-query';
import { QueryKey } from '@/lib/query-client';
import { getAllDatasources } from '@/lib/services/datasource.service';
import { DatasourceModel } from '@/lib/models/datasource.model';
import useApp from '@/hooks/use-app.hook';
import { getAvailableAgents } from '@/lib/services/shortcut.service';

const LOCAL_STORAGE_KEY = 'user-settings';

const loadFromLocalStorage = () => {
  const settings = getLocal(LOCAL_STORAGE_KEY, true) as {
    datasources: { id: string; enabled: boolean }[];
  };
  const datasources: Map<string, Datasource> = new Map();

  if (Array.isArray(settings?.datasources)) {
    settings?.datasources?.forEach(({ id, enabled }) => {
      datasources.set(id, { model: new DatasourceModel(), enabled });
    });
  }

  return { datasources };
};

const cachedUserSettings = loadFromLocalStorage();

export function UserSettingsProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [usePublicInternetDatasource] = useState(false);
  const [datasources, setDatasources] = useState<{ map: Map<string, Datasource> }>({
    map: new Map(),
  });

  const { signedIn } = useApp();

  const { data: loadedDatasources, isLoading: dsLoading } = useQuery<DatasourceModel[]>(
    [QueryKey.DatasourceList],
    async () => getAllDatasources(),
    {
      enabled: signedIn,
    }
  );

  const { data: loadedAgents, isLoading: agentsLoading } = useQuery(
    [QueryKey.AgentsList],
    async () => getAvailableAgents(),
    {
      enabled: signedIn,
    }
  );

  const enablePublicSearch = useCallback(() => {
    setDatasources((old) => {
      const updated = new Map(old.map);
      Array.from(updated.entries())
        .filter(([, { model }]) => model.isPublicInternet)
        .forEach(([datasourceId, { model }]) => {
          updated.set(datasourceId, { model, enabled: true });
        });

      return { map: updated };
    });
  }, []);

  const disablePublicSearch = useCallback(() => {
    setDatasources((old) => {
      const updated = new Map(old.map);
      Array.from(updated.entries())
        .filter(([, { model }]) => model.isPublicInternet)
        .forEach(([datasourceId, { model }]) => {
          updated.set(datasourceId, { model, enabled: false });
        });

      return { map: updated };
    });
  }, []);

  const publicSearchAvailable = useMemo(() => {
    return Array.from(datasources.map.entries()).some(([, { model }]) => model.isPublicInternet);
  }, [datasources]);

  const publicSearchEnabled = useMemo(() => {
    return Array.from(datasources.map.entries()).some(
      ([, { model, enabled }]) => model.isPublicInternet && enabled
    );
  }, [datasources]);

  const enableDatasource = useCallback((datasourceId: string | string[]) => {
    setDatasources((old) => {
      const updated = new Map(old.map);

      const datasourceIds = Array.isArray(datasourceId) ? datasourceId : [datasourceId];

      datasourceIds.forEach((datasourceId) => {
        const datasource = updated.get(datasourceId);

        if (datasource) {
          datasource.enabled = true;
          updated.set(datasourceId, datasource);
        }
      });

      return { map: updated };
    });
  }, []);

  const disableDatasource = useCallback((datasourceId: string | string[]) => {
    setDatasources((old) => {
      const updated = new Map(old.map);

      const datasourceIds = Array.isArray(datasourceId) ? datasourceId : [datasourceId];

      datasourceIds.forEach((datasourceId) => {
        const datasource = updated.get(datasourceId);

        if (datasource) {
          datasource.enabled = false;
          updated.set(datasourceId, datasource);
        }
      });

      return { map: updated };
    });
  }, []);

  useEffect(() => {
    if (!Array.isArray(loadedDatasources)) {
      return;
    }

    setDatasources((old) => {
      const updated: Map<string, Datasource> = new Map();

      const { datasources: lsDatasources } = cachedUserSettings;

      loadedDatasources.forEach((datasourceModel) => {
        const datasourceId = datasourceModel.id;
        let enabled = true;

        if (old.map.has(datasourceId)) {
          enabled = !!old.map.get(datasourceId)?.enabled;
        } else {
          if (lsDatasources.has(datasourceId)) {
            enabled = !!lsDatasources.get(datasourceId)?.enabled;
          }
        }

        // force this setting off by default while public internet search context is not working that well
        if (datasourceModel.isPublicInternet) {
          enabled = false;
        }

        updated.set(datasourceId, { model: datasourceModel, enabled });
      });

      return { map: updated };
    });
  }, [loadedDatasources]);

  useEffect(() => {
    setLocal(LOCAL_STORAGE_KEY, {
      datasources: Array.from(datasources.map.entries()).map(([id, { enabled }]) => ({
        id,
        enabled,
      })),
    });
  }, [datasources]);

  return (
    <UserSettingsContext.Provider
      value={{
        usePublicInternetDatasource,
        enableDatasource,
        disableDatasource,
        enablePublicSearch,
        disablePublicSearch,
        publicSearchAvailable,
        publicSearchEnabled,
        datasources: datasources.map,
        agents: loadedAgents || [],
        isLoading: dsLoading || agentsLoading,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
}
