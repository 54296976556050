import { LoadState } from '@/lib/helpers';
import { ShortcutMessage, ShortcutModel } from '@/lib/models/shortcut.model';
import { ThreadMessageModel } from '@/lib/models/thread/thread-message.model';
import { ThreadModel } from '@/lib/models/thread/thread.model';
import { StreamControlSignal } from '@/lib/services/thread-stream.service';
import { createContext } from 'react';

/*
 * Message Status
 * - stream: the app should update the page with content and control messages, the model is responding with a stream of SSE events
 * - open: default, starting state. the user is able to send a message, model is not doing anything
 * - send: the user's prompt is in-flight and being sent to the model via REST
 */

export type MessageStatus = 'open' | 'send' | 'stream';

export type ThreadSettings = {
  privateModelId: string;
  agentId: string;
};

export interface ThreadContextSettings {
  loadState: LoadState;
  thread: ThreadModel;
  settings: ThreadSettings;
  sendMessage: (content: string, shortcut?: ShortcutMessage) => boolean;
  controlSignal: StreamControlSignal | null;
  messageStatus: MessageStatus;
  working: boolean;
  streamContent: string;
  setWorking: (working: boolean) => void;
  loadThread: (threadId?: string, agentId?: string) => Promise<boolean>;
  loadShortcut: (shortcut: ShortcutModel) => boolean;
  addMessage: (MediaCapabilities: ThreadMessageModel) => void;
  sendDeckMessage: (message: ThreadMessageModel) => boolean;
  removeDeckMessage: (messageId: string) => void;
  closeStream: () => void;
  setPrivateModelId: (privateModelId: string) => void;
  setAgentId: (agentId: string) => void;
}

export const ThreadContext = createContext<ThreadContextSettings>({
  loadState: 'unloaded',
  thread: new ThreadModel(),
  settings: { agentId: '', privateModelId: '' },
  sendMessage: () => false,
  controlSignal: null,
  messageStatus: 'open',
  working: false, // whether or not the user has a shortcut form loaded and is working on filling it out
  streamContent: '',
  setWorking: () => null,
  loadThread: () => Promise.resolve(false),
  loadShortcut: () => false,
  addMessage: () => null,
  sendDeckMessage: () => false,
  removeDeckMessage: () => null,
  closeStream: () => null,
  setPrivateModelId: () => null,
  setAgentId: () => null,
});

export enum ThreadError {
  NotFoundThread = 'The thread was not found',
  NotFoundMessage = 'The message was not found',

  // loadThread
  SendingSwitchThread = 'Please wait for a response before opening a different thread',
  WorkingSwitchThread = 'Please complete or cancel the current shortcut before opening a different thread',

  // loadShortcut
  SendingLoadShortcut = 'Please wait for a response before opening a shortcut',
  WorkingLoadShortcut = 'Please complete or cancel the current shortcut before opening another one',

  // sendMessage
  // sendDeckMessage
  Sending = 'Please wait for a response before sending a message',
  Working = 'Please complete or cancel the current shortcut before sending a message',

  NoDisassemble = 'There was a network error',

  SessionExpired = 'Your session expired. Please reload the page.',
}
